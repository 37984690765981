import React from "react";

export const RiseofPiracy = (props) => {
  return (
    <div id="about">
      <div className="container" id="riseofpiracy">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/riseofpiracy.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
            <span class="h4 text-color">Original IP </span>
              <h2>RISE OF PIRACY</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Learn more</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d.title}-${i}`}><a href={`${d.url}`} target="_blank">{d.title}</a></li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                {/*<div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                    </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
